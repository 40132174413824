<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>人员管理</el-breadcrumb-item>
        <el-breadcrumb-item v-if="user.user_type == 3"
          >职员列表</el-breadcrumb-item
        >
        <el-breadcrumb-item v-else>监管人员列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt0">
      <div class="pt20" style="text-align:left;">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-select
              clearable
              v-model="provinceTemp"
              :disabled="provinceDis"
              placeholder="请选择省级"
              @change="handleChange($event, 2)"
              value-key="region_name"
            >
              <el-option
                v-for="item in provinceOptions"
                :key="item.region_code"
                :label="item.region_name"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="cityTemp"
              :disabled="cityDis"
              placeholder="请选择市级"
              @change="handleChange($event, 3)"
              value-key="region_name"
            >
              <el-option
                v-for="item in cityOptions"
                :key="item.region_code"
                :label="item.region_name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="areaTemp"
              :disabled="areaDis"
              placeholder="请选择区/县"
              @change="handleChange($event, 4)"
              value-key="region_name"
            >
              <el-option
                v-for="item in areaOptions"
                :key="item.region_code"
                :label="item.region_name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="townTemp"
              placeholder="请选择街道/镇"
              @change="handleChange($event, 5)"
              value-key="region_name"
            >
              <el-option
                v-for="item in townOptions"
                :key="item.region_code"
                :label="item.region_name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="villTemp"
              placeholder="请选择居委会/村"
              value-key="region_name"
              @change="handleChange($event, 6)"
            >
              <el-option
                v-for="item in villOptions"
                :key="item.region_code"
                :label="item.region_name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              placeholder="姓名"
              v-model="page.user_name"
              clearable
              @clear="onSubmit(page.user_name)"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              placeholder="登录账号"
              v-model="page.user_loginname"
              clearable
              @clear="onSubmit(page.user_mobilephone)"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="page.user_state"
              clearable
              placeholder="全部"
              @change="onSubmit(page.user_state)"
            >
              <el-option
                v-for="(item, index) in statusOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="onSubmit"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item v-if="user.user_type == 3">
            <el-button type="primary" size="medium" @click="Add"
              >新增职员</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search mt20">
       <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select clearable v-model="provinceTemp" :disabled="provinceDis" placeholder="请选择省级" @change="handleChange($event,2)"
                value-key="region_name">
                <el-option v-for="item in provinceOptions"  :key="item.region_code" :label="item.region_name"
                  :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select clearable v-model="cityTemp" :disabled="cityDis" placeholder="请选择市级" @change="handleChange($event,3)"
                value-key="region_name">
                <el-option v-for="item in cityOptions" :key="item.region_code" :label="item.region_name" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select clearable v-model="areaTemp" :disabled="areaDis" placeholder="请选择区/县" @change="handleChange($event,4)"
                value-key="region_name">
                <el-option v-for="item in areaOptions" :key="item.region_code" :label="item.region_name" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select clearable v-model="townTemp" placeholder="请选择街道/镇" @change="handleChange($event,5)"
                value-key="region_name">
                <el-option v-for="item in townOptions" :key="item.region_code" :label="item.region_name" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select clearable v-model="villTemp" placeholder="请选择居委会/村" value-key="region_name"
                @change="handleChange($event,6)">
                <el-option v-for="item in villOptions" :key="item.region_code" :label="item.region_name" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium" >
         <el-input placeholder="姓名" v-model="page.user_name" clearable class="input-with-select" @clear="onSubmit(page.user_name)">
            </el-input>
        </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium" >
         <el-input placeholder="登录账号" v-model="page.user_loginname" clearable class="input-with-select" @clear="onSubmit(page.user_mobilephone)">
            </el-input>
        </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select v-model="page.user_state" clearable placeholder="全部" @change="onSubmit(page.user_state)">
                <el-option
                  v-for="(item,index) in statusOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
         <el-col :span="6" class="tl mr10">
           <el-form-item  size="medium">
          <el-button  type="primary"  @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
          <el-button type="primary" v-if="user.user_type==3" size="medium" @click="Add">新增职员</el-button>
        </el-form-item>
        </el-col>
      </el-form>
    </el-row> -->
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%" id="out-table">
          <el-table-column
            type="index"
            align="center"
            width="60"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="user_loginname"
            min-width="120"
            :show-overflow-tooltip="true"
            label="登录账号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="user_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="姓名"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            label="性别"
            min-width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.user_sex == 1 ? "男" : "女" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="所在地址"
            min-width="150"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.user_province }}{{ scope.row.user_city
              }}{{ scope.row.user_area }}{{ scope.row.user_town
              }}{{ scope.row.user_vill }}{{ scope.row.user_address }}
            </template>
          </el-table-column>
          <el-table-column
            prop="department_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="监管部门"
            align="center"
          ></el-table-column>

          <el-table-column
            align="center"
            label="状态"
            min-width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span
                class="f12"
                :class="scope.row.user_state === 1 ? 'c67c' : 'ce6a'"
                >{{ scope.row.user_state | statusFilter }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="goUpdate(scope.row.user_id)"
                >编辑</el-button
              >

              <el-button
                type="text"
                class="disib"
                :class="{ red: scope.row.user_state == 1 }"
                size="mini"
                @click="changeState(scope.row)"
                >{{ scope.row.user_state | antiStateFilter }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { local, session } from "../../../util/util";
import { mapState } from "vuex";
export default {
  data() {
    return {
      user: "",
      page: {
        pager_offset: "0",
        pager_openset: "10",
        user_type: "",
        user_name: "",
        user_loginname: "",
        user_state: "",
        department_type: "",
        query_param: "",
        user_province: "",
        user_city: "",
        user_area: "",
        user_town: "",
        user_vill: "",
      },
      timer: "",
      loading: false,
      statusOptions: [
        { label: "全部", value: "" },
        { label: "已启用", value: "1" },
        { label: "已禁用", value: "2" },
      ],
      currentPage: 1,
      total: 0,
      tableData: [],
      activeName: "1",
      company_id: "",
      provinceTemp: "",
      cityTemp: "",
      areaTemp: "",
      townTemp: "",
      villTemp: "",
      province: "",
      city: "",
      area: "",
      town: "",
      vill: "",
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      townOptions: [],
      villOptions: [],
      pisDisabl: true,
    };
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: "已启用",
        2: "已禁用",
      };
      return statusMap[status];
    },
    antiStateFilter(state) {
      const stateMap = {
        1: "禁用",
        2: "启用",
      };
      return stateMap[state];
    },
    antiStateFilter1(state) {
      const stateMap = {
        1: "是",
        2: "否",
      };
      return stateMap[state];
    },
  },
  computed: {
    // ...mapState({
    //   userPage: (state) => state.user.userPage
    // })
    provinceDis() {
      if (this.province) return true;
      else return false;
    },
    cityDis() {
      if (this.city) return true;
      else return false;
    },
    areaDis() {
      if (this.area) return true;
      else return false;
    },
  },
  created() {
    //  this.page = Object.assign({}, this.userPage);
    this.user = local.get("user");
    this.page.user_type = "2";
    this.page.department_type = String(this.user.department_type);
    this.page.query_param = "all_manage_user";
    this.currentPage = this.page.pager_offset / 10 + 1;
    this.initArea();
  },
  methods: {
    initArea() {
      let url = "/pc/department/" + this.user.user_id;
      //预加载监管部门区域
      axios.get(url).then((res) => {
        res = res.data.department;
        let i = 0;
        if (res.length > 0) {
          let level = res[0].level;
          for (let j = 0; j < res.length; j++) {
            if (level > res[j].level) i = j;
          }
          this.province = res[i].province;
          this.city = res[i].city;
          this.area = res[i].area;
          this.town = res[i].town;
          this.vill = res[i].vill;
          this.provinceTemp = res[i].province;
          this.cityTemp = res[i].city;
          this.areaTemp = res[i].area;
          this.townTemp = res[i].town;
          this.page.user_province = res[i].province;
          this.page.user_city = res[i].city;
          this.page.user_area = res[i].area;
          this.page.user_town = res[i].town;
          this.user.province = this.provinceTemp;
          this.user.city = this.cityTemp;
          this.user.area = this.areaTemp;
          this.user.town = this.townTemp;
          this.user.vill = this.villTemp;
          this.handleUserLevel(this.user);
          this.init();
        }
      });
    },
    init() {
      axios.get("/pc/user/list", this.page).then((v) => {
        this.tableData = v.data.user_list;
        this.total = v.data.pager_count;
      });
    },
    // 搜索
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.$store.dispatch("patrol/setNewPage", this.page);
      this.init(this.page);
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.$store.dispatch("patrol/setNewPage", this.page);
      this.init(this.page);
    },
    //新增处罚方式
    Add() {
      session.set("params", { id: "", name: "" });
      this.$router.push({ name: "UserListAdd1" });
    },
    //编辑
    goUpdate(id) {
      session.set("params", { id: id, name: "" });
      this.$router.push({ name: "UserListAdd1" });
    },
    //更改状态
    changeState(row) {
      let { user_id, user_state } = row;
      user_state = user_state == 1 ? 2 : 1;
      axios
        .put("/pc/user/state/update", { user_id, user_state })
        .then((response) => {
          this.init(this.page);
        });
    },
    async handleChange(item, type) {
      let regionData;
      if (item.region_code == undefined) {
        regionData = "";
      } else {
        regionData = await this.fetchRegionData(type, item.region_code);
      }

      switch (type) {
        case 2:
          this.cityOptions = regionData;
          this.areaOptions = [];
          this.townOptions = [];
          this.villOptions = [];
          this.page.user_province = item.region_name;
          this.page.user_city = "";
          this.page.user_area = "";
          this.page.user_town = "";
          this.page.user_vill = "";
          this.cityTemp = "";
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 3:
          this.areaOptions = regionData;
          this.townOptions = [];
          this.villOptions = [];
          this.page.user_city = item.region_name;
          this.page.user_area = "";
          this.page.user_town = "";
          this.page.user_vill = "";
          this.areaTemp = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 4:
          this.townOptions = regionData;
          this.villOptions = [];
          this.page.user_area = item.region_name;
          this.page.user_town = "";
          this.page.user_vill = "";
          this.townTemp = "";
          this.villTemp = "";
          break;
        case 5:
          this.villOptions = regionData;
          this.page.town = item.region_name;
          this.townTemp = item.region_name;
          this.page.vill = "";
          this.villTemp = "";
          break;
        case 6:
          this.page.vill = item.region_name;
          this.villTemp = item.region_name;
          break;
      }
      this.onSubmit();
    },
    async handleUserLevel(userData) {
      if (parseInt(userData.user_type) > 0) {
        this.provinceOptions = await this.fetchRegionData(1);
        let province = this.provinceOptions.find(
          (item) => item.region_name == userData.province
        );
        this.provinceTemp = province;
        if (
          parseInt(userData.user_type) >= 1 &&
          userData.province != null &&
          userData.province != ""
        ) {
          this.cityOptions = await this.fetchRegionData(
            2,
            province.region_code
          );
          let city = this.cityOptions.find(
            (item) => item.region_name == userData.city
          );
          if (parseInt(userData.user_type) >= 2 && userData.city != null) {
            this.areaOptions = await this.fetchRegionData(3, city.region_code);
            let area = this.areaOptions.find(
              (item) => item.region_name == userData.area
            );
            this.cityTemp = city;
            if (userData.area != null) {
              this.townOptions = await this.fetchRegionData(
                4,
                area.region_code
              );
              let town = this.townOptions.find(
                (item) => item.region_name == userData.town
              );
              this.areaTemp = area;
              if (userData.town != null) {
                this.villOptions = await this.fetchRegionData(
                  5,
                  town.region_code
                );
                let vill = this.villOptions.find(
                  (item) => item.region_name == userData.vill
                );
                this.townTemp = town;
                if (userData.vill != null) {
                  this.villTemp = vill;
                }
              }
            }
          }
        }
      }
    },
    fetchRegionData(type, region_high_code) {
      let region_type = type.toString();
      return new Promise((resolve, reject) => {
        axios
          .get("/pc/region/all", { region_type, region_high_code })
          .then((response) => {
            resolve(response.data.region_list);
          })
          .catch((err) => {
            reject(err);
          });
      });
      //  }
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>
